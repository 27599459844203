import NumberFormat from 'react-number-format';
import { FaTrash } from "react-icons/fa";
import Hover from './hovercomponent';

const Variant = ({ smartShipCheck, minCartAutoshipCheck, _onLoadSetCostType, is_autoship, Cart, deleteproduct, index, register, Add, Sub, updateqty, setcosttype, costtype, swithtoautoship }) => {
  //check product is selected to autoship or normal

  const objIndex = costtype?.data?.findIndex((obj => +(obj.id) == +(Cart?.variant?.id)));
  let cost;

  cost = objIndex === -1 ?
    Cart?.is_autoship === 'True' ? Cart?.variant?.autoship_cost_price : Cart?.variant?.cost_price
    :
    objIndex ?
      costtype.data[objIndex].value === 'Normal' ?
        Cart?.variant?.cost_price
        :
        costtype.data[objIndex].value === 'AutoShip' ?
          Cart?.variant?.autoship_cost_price : ""
      :
      Cart?.is_autoship === 'True' ? Cart?.variant?.autoship_cost_price : Cart?.variant?.cost_price

  is_autoship === 'True' ?
    cost = Cart?.variant?.autoship_cost_price
    :
    ""

  return (<>
    <tr key={index}>
      <td>
        <div className="cart-image">
          {Cart?.variant?.product_variant_images[0]?.image ?
            <img src={`${process.env.API_URL}${Cart?.variant.product_variant_images[0]?.image}`} />
            :
            <img src="/images/no-image.jpg" />
          }
        </div>
      </td>
      <td>
        <div className="cart-product-details">
          {Cart?.product?.name}({Cart?.variant?.name})
        </div>
      </td>
      <td>
        <div className="">
          {["Product", "Bundle"].includes(Cart?.product?.product_type) &&
            <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
              data-product-id={Cart?.product?.id}
              data-product-qty={Cart?.quantity}
              data-variant-id={null}

              onChange={(e) => _setAutoshipFunction(e, Cart?.product?.id, Cart?.id)}
              value={_.find(costtype, { orderId: Cart?.id })?.autoship}
            >
              <option value="Normal">One-time Only</option>
              <option value="AutoShip">SmartShip</option>

            </select>
          }
        </div>
      </td>
      <td>
        <div className="main-qty-sec">
          <div className="box">
            {Cart?.qty_display &&
              <div id="qty">
                <button type="button" name={`${Cart?.product?.id},${Cart?.variant?.id}`} data-value={Cart?.variant?.quantity} id={Cart?.variant?.id} className="sub" value={Cart?.quantity} onClick={(e) => { Sub(e) }}>-</button>
                <input
                  name={`${Cart?.product?.id},${Cart?.variant?.id}`}
                  type="text"
                  ref={register &&
                    register({
                      valueAsNumber: true,
                    })
                  }
                  value={+(updateqty?.id) === +(Cart?.variant?.id) ? +(updateqty.value) : Cart?.quantity}
                  min={1}
                  max={Cart?.variant?.quantity}
                />
                <button type="button" name={`${Cart?.product?.id},${Cart?.variant?.id}`} data-value={Cart?.variant?.quantity} id={Cart?.variant?.id} className="add" value={Cart?.quantity} onClick={(e) => { Add(e) }}>+</button>
              </div>
            }
          </div>
        </div>
      </td>
      <td>
        <div className="cart-product-details">
          <div className="title">
            <NumberFormat
              value={parseFloat(Cart?.variant?.cost_price * Cart?.quantity).toFixed(2)}
              displayType={'text'} thousandSeparator={true}
              prefix={'$'}
              renderText={value => <div>
                {(minCartAutoshipCheck !== "True" && smartShipCheck !== true)
                  ?
                  value
                  :
                  `$${parseFloat(Cart?.variant?.[`${(minCartAutoshipCheck === "True" || smartShipCheck) ? "autoship_cost_price" : "cost_price"}`] * Cart?.quantity).toFixed(2)}` + (Cart?.is_autoship === "True" ? ' / $' + parseFloat(Cart?.variant?.autoship_cost_price * Cart?.quantity).toFixed(2) : "") + ' SmartShip'}</div>} />
          </div>
          {is_autoship === "True" ?
            <div className="title">
              <Hover data="Ships now & after 28 days" />
            </div>
            :
            <div className="title">
              <Hover data="SmartShip is deactivated" />
            </div>
          }
        </div>
      </td>
      <td>
        <div className="pro-cart-table cart-product-qty-del">
          <button className="dlt" >
            <FaTrash onClick={(e) => { deleteproduct(Cart?.product?.id, Cart?.variant?.id) }} />
          </button>
        </div>
      </td>
    </tr>
  </>)
}
export default Variant;