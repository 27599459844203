import React from 'react';
import _ from 'lodash';
import usePermoterhook from './PromoterPack';
import InnerHtml from './commonPackJsx';
import { UpperTextCss, CustomPack } from './PromoterCompStyle';

function CustomPackComp(props) {
    // const router = useRouter();

    const [
        packProduct, addToCart,
        allProductData,
        open, setOpen,
        customSelectedData, setCustomeData,
        eligiableMsg, setEligiableMsg,
        minBV, setMinBv,
        totalAmount, setTotalAmount,
        quantityCheck, setQuantityCheck,
        totalWeight, setTotalWeight
    ] = usePermoterhook(props)

    const common = {
        setOpen,
        customSelectedData, setCustomeData,
        eligiableMsg, setEligiableMsg,
        minBV, setMinBv, totalAmount, setTotalAmount,
        quantityCheck, setQuantityCheck,
        totalWeight, setTotalWeight
    }
    return (<>
        <UpperTextCss>
            {eligiableMsg ? <p>{eligiableMsg}</p> : ""}
            <div className="bonus_main_container">
                <div className="bonus_container common_value">
                    <div>BonusValue Required</div>
                    <div>{parseFloat(minBV, 2)}</div>
                </div>
                <div className="totalAmount_Container common_value">
                    <div>Total Amount</div>
                    <div>{parseFloat(totalAmount, 2)}</div>
                </div>
            </div>
        </UpperTextCss>
        <CustomPack>
            <InnerHtml
                contents={{
                    data: allProductData?.length > 0 ? allProductData : [{}],
                    partOf: props?.partOf,
                    addToCart: () => alert("No"),
                    content: "custom",
                    section: props?.section,
                    ...common
                }} />
        </CustomPack>
    </>
    )
}

export default CustomPackComp;