import styled from "styled-components";

const ViewCartStyle = styled.div`
  & .main_viewCart_section_class {
    height: 100%;
    &.cart-sec {
      padding: 60px 0 85px !important;
      margin-top: 45px;
      h1 {
        font-weight: 600;
        font-size: 26px;
        text-transform: uppercase;
      }
    }
    .table_container {
      height: 100%;
      max-height: 950px;
      overflow-y: auto;
      .whistlist-table {
        width: 100%;
        /* border: 1px solid rgb(221, 221, 221);  */
        /* border-radius: 40px; */
        height: 45px;
        /* max-width: 200px; */
        background: #fff;
        padding: 0 10px;
        tr {
          &.checkout-btn + tr {
            background: #fff;
          }
          &:nth-child(2n) {
            background: #f9f9f9;
          }
          &.checkout-btn {
            width: 100%;
            float: none;
            ul {
              padding: 0;
              margin: 40px 57px 40px 0;
              list-style: none;
              width: 19%;
              float: right;
              @media (max-width: 991px) {
                margin: 20px 0;
              }
            }
            button {
              min-width: 308px;
              border-radius: 25px;
              font-size: 17px;
              font-weight: 600;
              height: 47px;
              background: #06356a;
              border: none;
            }
          }
          th {
            text-align: center;
            background: #06356a;
            color: #fff;
            text-transform: uppercase;
            padding: 15px 0;
            position: sticky;
            top: 0;
            z-index: 9;
            text-align: center !important;
            /* &:first-child {
              border-top-left-radius: 0px;
            }
            &:last-child {
              border-top-right-radius: 0px;
            } */
            &:nth-child(4) {
              min-width: 90px;
            }
            @media (max-width: 991px) {
              padding: 12px;
            }
          }

          td {
            text-align: center;
            .main-qty-sec {
              margin: 13px -15px 15px;
              display: flex;
              justify-content: center;
              @media (max-width: 991px) {
                justify-content: center;
                margin: 0 auto;
              }
            }
            select {
              width: 155px;
              height: 40px;
              border: 1px solid #ddd;
              border-radius: 50px;
              background: transparent;
              padding: 0 10px;
            }
            #qty {
              @media (max-width: 991px) {
                justify-content: center;
              }
              input {
                background: #fff;
                width: 40px !important;
                border-right: 1px solid var(--blue);
                border-left: 1px solid var(--blue);
              }
              button {
                width: 38px;
              }
              /* .sub {
                left: -2px;
                top: -4px !important;
              }
              .add {
                top: -4px !important;
              } */
            }
            /* &:not(:first-child) {
              border-left: 1px solid #f9f9f9;
            } */
          }
        }

        .cart-product-details {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            border-radius: 50px;
            width: 30px;
            background: #e7f2ff;
            max-width: 30px;
            min-width: 30px;
            height: 30px;
            margin-left: 10px;
            font-size: 12px;
            color: #06356a;
          }
        }
        .Add_to_cart button {
          font-size: 13px !important;
          font-weight: 600;
          border-radius: 50px;
        }
        .pro-cart-table {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .checkout_button {
          float: right;
          margin: 0 50px 20px 0;
          @media (max-width: 991px) {
            margin: 0;
          }
          button {
            min-width: 316px;
            height: 49px;
            border-radius: 25px;
            font-weight: 600;
            font-size: 18px;
            background: #06356a;
            border: 2px solid #06356a;
            &:hover {
              background: #fff;
              color: #06356a;
            }
            @media (max-width: 767px) {
              min-width: 120px;
              height: 42px;
              font-size: 14px;
            }
          }
        }
      }
      @media (max-width: 991px) {
        overflow-x: auto;
      }
      @media (max-width: 767px) {
        overflow-x: visible;
        .checkout-btn td {
          padding-left: 0 !important;
        }
        .checkout-btn td:before {
          display: none !important;
        }
        tr.checkout-btn ul {
          margin: 20px 0;
          width: 100%;
        }
      }
    }
  }
  .mini-cart-sec
    .mini-cart
    .min-cat-uppper
    .mini-cart-des
    .main-qty-sec
    .box
    #qty {
    max-width: 80% !important;
    border: 0px solid #ddd;
    display: flex;
  }
  table.Cart_product.whistlist-table .main-qty-sec {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }

  table.Cart_product.whistlist-table .main-qty-sec .box {
    padding: 0;
    min-width: 120px;
  }

  /*Cart
https://ccmlm.com
*/

  @media (max-width: 1440px) {
    .main_viewCart_section_class .table_container .whistlist-table tr td #qty {
      display: flex !important;
    }
    .main_viewCart_section_class .main-qty-sec .box #qty button {
      height: 28px !important;
    }
  }
  @media (max-width: 1024px) {
    .main-qty-sec .box {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }

  @media (max-width: 480px) {
    table.Cart_product.whistlist-table th {
      min-width: 250px;
    }
  }
`;

const SecondTableCss = styled.div`
  margin-top: 40px;
  .heading_of_pack {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #06356b;
    color: #fff;
    font-size: 15px;
    font-weight: 600;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
    .title_of_pack {
      padding: 15px;
      font-size: 18px;
      width: 100%;
    }
    .amount_and_edit {
      display: flex;
      flex-flow: nowrap;
      gap: 20px;
    }
    .button_for_edit {
      display: flex;
      gap: 20px;
      div {
        padding: 15px;
      }
    }
    .amount {
      padding: 18px;
      margin-right: 0;
    }
    @media (max-width: 767px) {
      h3.title {
        margin: 10px;
      }
    }
  }
  .table_container {
    margin-top: 10px;
    @media (max-width: 991px) {
      overflow-x: auto;
    }
    table {
      tr {
        th {
          text-align: center;
          padding: 15px 20px;
          background: #06356b;
          color: #fff;
        }
        td {
          padding: 15px 0px;
        }
      }
    }
  }
`;

export default ViewCartStyle;
export { SecondTableCss };
