import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "@api/Apis";
import { useRouter } from 'next/router';

// const hookName = "promoter_hook";
export default function usePermoterhook(props) {
    const route = useRouter();
    const store = route.query.page;

    const [packProduct, setPackProduct] = useState([]);
    const [allProductData, setAllproductData] = useState([]);
    const [open, setOpen] = useState(false);
    const [customSelectedData, setCustomeData] = useState([]);
    const [eligiableMsg, setEligiableMsg] = useState("");
    const [minBV, setMinBv] = useState(150);
    const [totalAmount, setTotalAmount] = useState(0);
    const [quantityCheck, setQuantityCheck] = useState([]);
    const [totalWeight, setTotalWeight] = useState(0);
    const [logintoken, setLogintoken] = useState('')
    const { setshowminicart, setshowloader, setInnerLoader } = props;

    let MEMBERSHIP_PRODUCT = {};
    const promoterPackFunction = async (token = "") => {
        await api.promoterPackApi({ token }).then((res) => {
            const { code, promoter_packs } = res?.data;
            if (res?.status === 200 && code === 1) {

                const data = _.find(promoter_packs, { name: "MEMBERSHIP" });
                MEMBERSHIP_PRODUCT = data;

                if (props?.section !== "viewCart") {
                    if (data) {
                        setCustomeData([{
                            id: data?.id,
                            product_name: data?.name,
                            qty: 1,
                            bonus_value: data?.bonus_value,
                            weight: 0,
                            autoship_cost_price: data?.autoship_cost_price
                        }]);
                        setMinBv(150 - +data?.bonus_value);
                        setTotalAmount(data?.autoship_cost_price);
                    } else {
                        setCustomeData([]);
                        setMinBv(150);
                        setTotalAmount(0);
                        setTotalWeight(0);
                    }
                }
                setPackProduct(_.reject(promoter_packs, { name: "MEMBERSHIP" }));
                // setInnerLoader(false)
            }
        })

    }

    const getAllProductdata = async () => {
        if (store) {
            await api.getAllProduct(store).then(res => {
                if (res?.status === 200) {
                    setAllproductData([MEMBERSHIP_PRODUCT, ...res?.data?.products,]);
                    // setInnerLoader(false)
                }
            })
        }
    }

    useEffect(() => {
        const loginUserData = localStorage.getItem("Token");
        setLogintoken(loginUserData)

        // setInnerLoader(true)
        promoterPackFunction(loginUserData);
        getAllProductdata();
        const data = localStorage.getItem('packProduct');
        if (props?.section === "viewCart" && data?.length > 0) {
            let values = JSON.parse(data);
            setMinBv(values?.customPack?.bonusValue || 0);
            setEligiableMsg(values?.customPack?.eligiable);
            setTotalAmount(values?.customPack?.totalAmount);
            setCustomeData(values?.customPack?.products);
            setQuantityCheck(values?.customPack?.quantityData);
            setTotalWeight(values?.customPack?.totalWeight)
        } else {
            setEligiableMsg(null);
        }

    }, [store, open]);

    function addToCart(id, quantity) {
        const formData = {
            product_id: id,
            variant_id: null,
            token: localStorage.getItem('Token'),
            quantity: quantity,
            is_autoship: false
        };
        addCard(formData);
    }

    async function addCard(formData) {
        await api.addToCart(formData).then(res => {
            if (res?.data?.code === 1) {
                // toast.success(res.data.message, { duration: 5 })
                setshowminicart(true);
                if (props?.partOf) {
                    if (props?.signUpCredential?.email) {
                        props?.LoginSubmit(props?.signUpCredential);
                    } else {
                        window.location.assign('/us/signup/?d=member')
                    }
                }
            }
        });
    }

    return [
        packProduct, addToCart,
        allProductData,
        open, setOpen,
        customSelectedData, setCustomeData,
        eligiableMsg, setEligiableMsg,
        minBV, setMinBv,
        totalAmount, setTotalAmount,
        quantityCheck, setQuantityCheck,
        totalWeight, setTotalWeight
    ]
}

