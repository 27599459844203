import Head from "next/head";
import _ from 'lodash';
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from '../../../api/Apis'
import Product from "../../Components/Cart/ProductComponext";
import Variant from "../../Components/Cart/VariantComponents";
import { toast } from 'react-toastify';
import cookieCutter from 'cookie-cutter'
import ViewCartStyle from "./ViewCartStyle";
import ViewCartPopup from "@Components/ViewCartPopup";
import SvgAnimationBackground from "@Components/Common/SvgAnimationBackground";
import { SecondTableCss } from "./ViewCartStyle";
import NumberFormatComp from "@Components/Common/NumberFormatComp";
import CustomPackTable from "./CustomPackTable";
import { BYthreeGetONeFree } from "@Components/Common/commonMethods";

export const SubTotalSection = ({ message, total, Validatelogin }) => {
  const [customData, setCustomData] = useState()

  useEffect(() => {
    const customPackData = localStorage.getItem('packProduct')
    setCustomData(customPackData)
  }, [])
  return (

    message !== 'Cart is empty' &&
    <>
      <tr className="checkout-btn">
        <td colSpan={6} >
          <ul>
            <li><strong>Sub-total:</strong>
              <NumberFormatComp value={total} />
            </li>
            <li>
              <strong>Total:</strong>
              <NumberFormatComp value={total} />
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td colSpan={6} >
          <div className="checkout_button">
            <button type="button" className="btn btn-primary" onClick={(e) => { Validatelogin(e) }} >Checkout</button>
          </div>
        </td>
      </tr>
    </>
  )
}

export default function Cart({ showloader, smartShipCheck, setshowloader, isLogin, setInnerLoader, setSmartShipCheck, cartdata, setCartData, ...props }) {

  // const [cartdata, setCartData] = useState();
  const [updateqty, setupdateqty] = useState({ id: '', value: '' });
  const [setdeletemessage, setDeleteMessage] = useState();
  const [variantid, setvariantid] = useState();
  const [logintoken, setToken] = useState();
  const [costtype, setcosttype] = useState([])
  const [is_autoship, setis_autoship] = useState();
  const [open, setOpen] = useState(false);
  const [checkCustomPack, setCheckCustomPack] = useState({ products: [], totalAmount: 0 });
  const route = useRouter();
  const { register, handleSubmit, errors } = useForm();
  const popRef = React.useRef(null);

  //delete product from cart

  const deleteproduct = async (productId, variantId, cartId, is_autoship) => {
    // setshowloader(true);
    // setInnerLoader(true)
    const formDataDelete = { product_id: productId, variant_id: variantId, id: cartId, is_autoship: is_autoship, token: logintoken };
    await api.deleteProductFromAddToCart(formDataDelete).then(res => {
      setDeleteMessage(res.data)
      if (res?.data?.code === 1) {
        // toast.success('Product deleted', {
        //   duration: 5
        // });
        api.getAllCartProduct(logintoken).then(res => {
          const CARTDATA = BYthreeGetONeFree(res?.data)
          setCartData(CARTDATA)
          // setCartData(res?.data);
          GetCounts(CARTDATA?.products?.length)
          _onLoadSetCostType(res?.data?.products);
          // setshowloader(false);
          // setInnerLoader(false)

        })
      }

    })
  }
  function _onLoadSetCostType(data) {
    const cosTypeData = [];
    _.forEach(data, (row) => {
      if (row?.is_autoship === "True") {
        setSmartShipCheck(true);
      }
      cosTypeData.push({ id: +row?.product?.id, orderId: row?.id, autoship: row?.is_autoship === "True" ? "AutoShip" : "Normal" });
    })
    setcosttype(cosTypeData)
  }
  function GetCounts(count) {
    const dataSss = localStorage.getItem('packProduct') ? JSON.parse(localStorage.getItem('packProduct')) : null
    let couuntValue = (dataSss !== null) ? count + 1 : count
    props?.setcounts(couuntValue)
  }

  //for qty counts


  useEffect(() => {
    const token = localStorage.getItem('Token') ? localStorage.getItem('Token') : null
    // setshowloader(true);
    setInnerLoader(true)
    setToken(token)
    if (_.keys(cartdata)?.length > 0) {
      setCartData(cartdata);
      setis_autoship(cartdata?.is_autoship_user);
      let QTYCOUNTS = cartdata?.products?.reduce(
        (prevValue, currentValue) => prevValue + (+currentValue?.quantity + currentValue?.extra_qty), 0);
      GetCounts(QTYCOUNTS)
      setInnerLoader(false)
      _onLoadSetCostType(cartdata?.products);
    } else {
      GetCounts(0);
      setInnerLoader(false)
    }
    // api.getAllCartProduct(token).then(res => {
    //   setCartData(res?.data);
    //   setis_autoship(res?.data?.is_autoship_user);
    //   GetCounts(res?.data?.products?.length)
    //   // setshowloader(false);
    //   setInnerLoader(false)
    //   _onLoadSetCostType(res?.data?.products);

    // });

    const data = localStorage.getItem('packProduct');
    if (data) {
      let values = JSON.parse(data);
      setCheckCustomPack({ products: values?.customPack?.products, totalAmount: values?.customPack?.totalAmount });
    }
  }, [cartdata]);

  // cart total
  let total = checkCustomPack?.totalAmount ? checkCustomPack?.totalAmount : 0;
  cartdata?.products?.forEach((cart) => {
    if (cartdata?.is_autoship_user === 'True' || smartShipCheck) {
      total = (cart.variant ? +(cart.variant.autoship_cost_price * cart.quantity) : +(cart.product.autoship_cost_price * cart.quantity)) + total;

    }
    else if (cart.is_autoship === "True") {
      total = (cart.variant ? +(cart.variant.cost_price * cart.quantity) : +(cart.product.cost_price * cart.quantity)) + total;
    } else {
      total = (cart.variant ? +(cart.variant.cost_price * cart.quantity) : +(cart.product.cost_price * cart.quantity)) + total;
    }
  });

  //on click checkout check is already login or not
  const Validatelogin = (e) => {

    // if (isLogin === true || logintoken !== null) {
    route.push(`/${route.query.page}/checkout/addresslist`)
    // }
    // else {
    //   popRef.current.setRefferalPopup(false);
    //   popRef.current.setGoToPromoterPack(false);
    //   popRef.current.setCorrectRefferal({ status: false, data: {} });
    //   setOpen(true);
    // }
  }
  //increase product qty
  const Add = (e, cartId) => {
    if (+(e.target.value) >= 0 && +(e.target.value) < +(e?.target?.dataset?.value)) {
      const data_update = e?.target?.name.split(',')
      let is_auto_ship = _.find(costtype, { orderId: cartId })?.autoship === "AutoShip" ? true : false;
      const datas = {
        product_id: +(data_update[0]),
        variant_id: +(data_update[1]),
        quantity: +(e.target.value) + 1,
        id: cartId,
        cookie_id: cookieCutter.get('sessionkey'),
        is_autoship: is_auto_ship
      }
      const update_data = {
        token: logintoken,
        datas: datas
      }
      setInnerLoader(true)
      api.updateCart(update_data).then(res => {
        // toast.success(res.data.message, {
        //   duration: 5
        // })
        api.getAllCartProduct(logintoken).then(res => {
          const CARTDATA = BYthreeGetONeFree(res?.data)
          setCartData(CARTDATA)
          _onLoadSetCostType(res?.data?.products);
          setInnerLoader(false)
        })
      })
    }
  }
  //descrease product qty
  const Sub = (e, cartId) => {
    // let is_auto_ship = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector('.form-select').value;
    setInnerLoader(true)
    const data_update = e?.target?.name.split(',')
    let is_auto_ship = _.find(costtype, { orderId: cartId })?.autoship === "AutoShip" ? true : false;

    const datas = {
      product_id: +(data_update[0]),
      variant_id: +(data_update[1]),
      quantity: +(e.target.value) > 0 ? (+(e.target.value) - 1) : +(e.target.value),
      cookie_id: cookieCutter.get('sessionkey'),
      id: cartId,
      is_autoship: is_auto_ship

    }
    const update_data = {
      token: logintoken,
      datas: datas
    }
    api.updateCart(update_data).then(res => {
      // toast.success(res.data.message, { duration: 5 })
      api.getAllCartProduct(logintoken).then(res => {
        const CARTDATA = BYthreeGetONeFree(res?.data)
        setCartData(CARTDATA)
        _onLoadSetCostType(res?.data?.products);
        setInnerLoader(false)

      })
    })
  }
  //Switch product to normal to autoship or autoship to normal

  const swithtoautoship = (e, cartId) => {
    setInnerLoader(true)
    let qty = e.currentTarget.getAttribute('data-product-qty')
    let variant_id = e.currentTarget.getAttribute('data-variant-id')
    let product_id = e.currentTarget.getAttribute('data-product-id')
    let types = e.currentTarget.value === 'AutoShip' ? true : false;
    const datas = {
      product_id: +(product_id),
      variant_id: variant_id,
      id: cartId,
      quantity: +(qty),
      cookie_id: cookieCutter.get('sessionkey'),
      is_autoship: types
    }
    setSmartShipCheck(types);
    const update_data = {
      token: logintoken,
      datas: datas
    }
    api.updateCart(update_data).then(res => {
      // toast.success(res.data.message, { duration: 5 })
      api.getAllCartProduct(logintoken).then(res => {
        const CARTDATA = BYthreeGetONeFree(res?.data)
        setCartData(CARTDATA)
        _onLoadSetCostType(res?.data?.products);
        setInnerLoader(false)
      })
    })

  }

  return (<>
    <Head>
      <title>Cart</title>
    </Head>
    <ViewCartStyle>
      <div className="cart-sec main_viewCart_section_class">
        <div className="container">
          <div className="cart-heading">
            <h1 className="title"> Shopping Cart  </h1>
          </div>
          <div className="table_container">
            <table className="Cart_product whistlist-table">
              {cartdata?.products &&
                <thead >
                  <tr>
                    <th>Product image</th>
                    <th>product name</th>
                    <th>Variation</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
              }
              <tbody>
                {cartdata?.message && <h3 className="title">{cartdata.message}</h3>}
                {cartdata?.products?.map((Cart, index) => {
                  return (<>
                    {Cart?.variant === null ?
                      <Product is_autoship={is_autoship}
                        {...props}
                        smartShipCheck={smartShipCheck}
                        key={index + 1}
                        swithtoautoship={swithtoautoship}
                        Cart={Cart} setcosttype={setcosttype}
                        setupdateqty={setupdateqty}
                        register={register}
                        deleteproduct={deleteproduct}
                        index={index}
                        Add={Add}
                        Sub={Sub}
                        updateqty={updateqty}
                        costtype={costtype} />
                      :
                      <Variant {...props} is_autoship={is_autoship}
                        smartShipCheck={smartShipCheck}
                        swithtoautoship={swithtoautoship} Cart={Cart}
                        setcosttype={setcosttype} register={register}
                        deleteproduct={deleteproduct} index={index}
                        Add={Add} Sub={Sub} updateqty={updateqty}
                        _onLoadSetCostType={(value) => _onLoadSetCostType(value)}
                        costtype={costtype} setupdateqty={setupdateqty} />
                    }
                  </>)
                })
                }
                {!(checkCustomPack?.products?.length > 0) &&
                  <SubTotalSection message={cartdata?.message} total={total} Validatelogin={Validatelogin} />
                }
              </tbody>
            </table>
          </div>
          {(checkCustomPack?.products?.length > 0) &&
            <CustomPackTable setCheckCustomPack={setCheckCustomPack}>
              <SubTotalSection message={"customPack"} total={total} Validatelogin={Validatelogin} />
            </CustomPackTable>
          }
        </div>
      </div>
    </ViewCartStyle>
    <ViewCartPopup ref={popRef} content={{
      open: open,
      setOpen: setOpen,
      section: "viewCart",
      setshowloader: setshowloader,
      ...props
    }} />
    <SvgAnimationBackground />
  </>);
}

