import Head from "next/head";
import Link from "next/link";
import router, { useRouter } from "next/router";
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import api from '../../../api/Apis'
import Product from "../../Components/Cart/MiniCart/ProductComponext";
import Variant from "../../Components/Cart/MiniCart/VariantComponents";
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import cookieCutter from 'cookie-cutter'
import MinCartStyle from "./MinCartStyle";
import _ from "lodash";
import ViewCartPopup from "@Components/ViewCartPopup";
import CustomPackTable from "./CustomPackTable";
import { SubTotalSection } from "./viewCart";
import NumberFormatComp from "@Components/Common/NumberFormatComp";
import { DeleteOutline } from "@material-ui/icons";

export default function Minicart({ cartdata, setActiveStep, activeStep, setCartData, smartShipCheck, getMiniCartProduct, setInnerLoader, promoterPackAvailability, isLogin,
  amountCalculations, setAmountCalculations, coupon, setcoupon, ...props }) {
  const wrapperRef = useRef(null);
  const [updateqty, setupdateqty] = useState({ id: '', value: '' });
  const [setdeletemessage, setDeleteMessage] = useState();
  const [logintoken, setToken] = useState();
  const [costtype, setcosttype] = useState({ data: [{ id: '', value: '' }] })
  const route = useRouter();

  const { register, handleSubmit, errors } = useForm();
  const [is_autoship, setis_autoship] = useState()
  const [open, setOpen] = useState(false);
  const [customPackProduct, setCustomerPackProduct] = useState()
  const popRef = React.useRef(null);

  //delete product from minicart
  const deleteproduct = async (e, cartId, autoship) => {
    const formDataDelete = { product_id: e?.target?.id, variant_id: e?.target?.dataset?.value, id: cartId, is_autoship: autoship, token: logintoken };
    await api.deleteProductFromAddToCart(formDataDelete).then(res => {
      setDeleteMessage(res.data)
      if (res?.data?.code === 1) {
        localStorage.removeItem("PromoterPack")
        getMiniCartProduct("delete", logintoken);
        setAmountCalculations();
        setcoupon()
      }
    })
  };
  const showHeader = route.pathname === "/[page]/signup" ? false : true;
  useEffect(() => {
    const custompackData = localStorage.getItem('packProduct') ? JSON.parse(localStorage.getItem('packProduct')) : null
    setCustomerPackProduct(custompackData)
    const token = localStorage.getItem('Token') ? localStorage.getItem('Token') : null
    setToken(token)
    getMiniCartProduct("onLoadData", token);
    document.addEventListener("click", handleClickOutside, false);

    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };

  }, [props?.showminicart]);

  // cart total
  let total = customPackProduct?.customPack?.totalAmount ? customPackProduct?.customPack?.totalAmount : 0;
  cartdata?.products?.forEach((cart) => {
    if (cartdata?.is_autoship_user === 'True' || smartShipCheck) {
      total = (cart.variant ? +(cart.variant.autoship_cost_price * cart.quantity) : +(cart.product.autoship_cost_price * cart.quantity)) + total;
    }
    else if (cart.is_autoship === "True") {
      total = (cart.variant ? +(cart.variant.cost_price * cart.quantity) : +(cart.product.cost_price * cart.quantity)) + total;
    }
    else {
      total = (cart.variant ? +(cart.variant.cost_price * cart.quantity) : +(cart.product.cost_price * cart.quantity)) + total;
    }
  });

  //onclick checkout validate user login or not.
  const Validatelogin = (e) => {
    setTimeout(() => {
      props?.setshowminicart(false)
    }, 2000);
    if (router?.query?.page === undefined) {
      route.push('/us/checkout/addresslist')
    }
    else {
      route.push(`/${route.query.page}/checkout/addresslist`)
    }

    // if (isLogin || logintoken !== null) {
    // route.push(`/${route.query.page}/checkout/addresslist`)
    // }
    // else {
    //   if (promoterPackAvailability !== true) {
    //     popRef.current.setRefferalPopup(false);
    //     popRef.current.setCorrectRefferal({ status: false, data: {} });
    //     popRef.current.setGoToPromoterPack(false);
    //     setOpen(true);
    //   } else {
    //     route.push(`/${route.query.page}/signup/?d=distributer`)
    //   }

    // }
  }
  //update cart data >>>>>>>>>>>>>>
  const UpdateCartData = async (datas, section) => {
    setInnerLoader(true)
    const update_data = {
      token: logintoken,
      datas: datas
    }
    await api.updateCart(update_data).then(res => {
      if (res.data.code === 1) {
        getMiniCartProduct(section, logintoken);
        setInnerLoader(false)
      }
      // toast.success(res.data.message, { duration: 5 });
    });
  }

  //add qty of product in mini cart
  const Add = async (e, cartId) => {
    let is_auto_ship = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector('.form-select')?.value;
    if (+(e.target.value) >= 0 && +(e.target.value) < +(e?.target?.dataset?.value)) {
      const data_update = e?.target?.name.split(',')
      const datas = {
        product_id: +(data_update[0]),
        variant_id: +(data_update[1]),
        quantity: +(e.target.value) + 1,
        id: cartId,
        cookie_id: cookieCutter.get('sessionkey'),
        is_autoship: is_auto_ship === 'AutoShip' ? true : false
      }
      UpdateCartData(datas, "Add");
      setcoupon()
      setAmountCalculations();
    }
  }
  //subtract qty of product from minicart.

  const Sub = (e, cartId) => {
    let is_auto_ship = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector('.form-select')?.value;
    const data_update = e?.target?.name.split(',')
    const datas = {
      product_id: +(data_update[0]),
      variant_id: +(data_update[1]),
      id: cartId,
      quantity: +(e.target.value) > 0 ? (+(e.target.value) - 1) : +(e.target.value),
      cookie_id: cookieCutter.get('sessionkey'),
      is_autoship: is_auto_ship === 'AutoShip' ? true : false
    }
    UpdateCartData(datas, "Sub");
    setcoupon()
    setAmountCalculations();


  }
  //close minicart
  const closeminicart = () => {
    props.setshowminicart(false)
  }

  //click outside of minicart
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props?.setshowminicart(false)
    }
  };

  //switch product from autoship to norma and normal to autoship
  const swithtoautoship = (e, cartId) => {
    let qty = e.currentTarget.getAttribute('data-product-qty');
    let variant_id = e.currentTarget.getAttribute('data-variant-id');
    let product_id = e.currentTarget.getAttribute('data-product-id');
    let types = e.currentTarget.value;

    const datas = {
      product_id: +(product_id),
      variant_id: variant_id,
      id: +cartId,
      quantity: +(qty),
      cookie_id: cookieCutter.get('sessionkey'),
      is_autoship: (types === 'AutoShip') ? true : false
    }
    UpdateCartData(datas, "switchAutoShip");
  }
  function handleReview() {
    if (activeStep !== 3) {
      setActiveStep((prevActiveStep) => {
        return prevActiveStep + 1
      })
    }
    props.setshowminicart(false)

  }

  function openMiniCart() {
    // document.body.classList.add("openminicart")
    props?.setshowminicart(true);
  }
  return (<>
    <Head>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
    </Head>

    <MinCartStyle>
      {props?.showminicart !== false ?
        <div className="mini-cart-sec" ref={wrapperRef}>
          <div className="container">
            <div className="cart-heading">
              <h1 className="title" > Shopping Cart  </h1>
              <button onClick={closeminicart}>X</button>
            </div>
            <div className="Cart_product">
              {cartdata?.message && <h3 className="title">{cartdata.message}</h3>}

              <div className="mini-cart-sc">
                {
                  cartdata?.products?.map((Cart, index) => {
                    return Cart?.variant === null ?
                      <Product
                        key={index + 1}
                        smartShipCheck={smartShipCheck}
                        minCartAutoshipCheck={props?.minCartAutoshipCheck}
                        swithtoautoship={swithtoautoship}
                        Cart={Cart}
                        productData={cartdata?.products}
                        setcosttype={setcosttype}
                        setupdateqty={setupdateqty}
                        register={register}
                        deleteproduct={deleteproduct}
                        index={index} Add={Add} Sub={Sub}
                        updateqty={updateqty}
                        costtype={costtype}
                        amountCalculations={amountCalculations}
                        setAmountCalculations={setAmountCalculations}
                        setcoupon={setcoupon}
                      />
                      :
                      <Variant
                        key={index + 1}
                        minCartAutoshipCheck={props?.minCartAutoshipCheck}
                        swithtoautoship={swithtoautoship}
                        productData={cartdata?.products}
                        Cart={Cart}
                        setcosttype={setcosttype}
                        register={register}
                        deleteproduct={deleteproduct}
                        index={index} Add={Add} Sub={Sub}
                        updateqty={updateqty}
                        costtype={costtype}
                        smartShipCheck={smartShipCheck}
                        setupdateqty={setupdateqty} />
                  })
                }
                {
                  customPackProduct?.customPack?.products?.length > 0 &&
                  <div className="mini-cart" >
                    <div className="min-cat-uppper">
                      <div className="cart-image">
                        <img src="/memberpack.png" />
                      </div>
                      <div className="mini-cart-des">
                        <div className="cart-product-details">CustomPack</div>
                        <div className="main-qty-sec">
                          <button>{customPackProduct?.customPack?.products?.length}</button>
                        </div>
                        <div className="cart-product-details">
                          <NumberFormatComp value={customPackProduct?.customPack?.totalAmount} />
                        </div>
                      </div>
                      <div className="cart-product-qty-del">
                        <button><DeleteOutline onClick={(e) => {
                          localStorage.removeItem('packProduct');
                          setCustomerPackProduct({});
                        }} className="dlt" /></button>
                      </div>
                    </div>
                  </div >
                }
              </div>

              {cartdata?.products && cartdata?.message !== 'Cart is empty' &&
                <div className="checkout-btn">
                  <div className="row">
                    <div className="col-md-12">
                      <ul>
                        <li>
                          <strong>Sub-total:</strong>
                          <NumberFormat value={parseFloat(total).toFixed(2)} displayType={'text'}
                            thousandSeparator={true} prefix={'$'}
                            renderText={value => <div>{value} USD</div>}
                          />
                        </li>
                        {cartdata?.discountAmount !== 0 &&
                          <li>
                            <strong>Discount Amount:</strong>
                            <NumberFormat value={parseFloat(cartdata?.discountAmount).toFixed(2)} displayType={'text'}
                              thousandSeparator={true} prefix={'$'}
                              renderText={value => <div>{value} USD ({cartdata?.currentDiscountPercent}%)</div>}
                            />
                          </li>
                        }
                        <li>
                          <strong>Total:</strong>
                          <NumberFormat value={parseFloat(cartdata?.discountAmount ? cartdata?.totalAfterDiscount : total).toFixed(2)} displayType={'text'}
                            thousandSeparator={true} prefix={'$'}
                            renderText={value => <div>{value} USD</div>}
                          />
                        </li>
                      </ul>
                    </div>
                    {
                      !showHeader ?
                        <div className="col-md-12">
                          <button type="button" className="btn btn-primary" onClick={() => handleReview()} >Review</button>
                        </div>
                        :
                        <>
                          <div className="d-flex btn-flex" >
                            <button type="button" className="btn btn-primary" onClick={() => props?.setshowminicart(false)} >Continue Shopping</button>

                            <button type="button" className="btn btn-primary" onClick={(e) => { Validatelogin(e) }} >Checkout</button>
                          </div>
                        </>
                    }
                  </div>
                </div>
              }
            </div>

          </div>
        </div>
        :
        <div className="mini-root-div" onClick={() => { props?.setshowminicart(true) }}>
          <div onClick={() => openMiniCart()} className="fa fa-shopping-cart">
            {cartdata?.products?.length > 0 &&
              <span>{props?.counts && !isNaN(props?.counts) && props?.counts}</span>
            }
          </div>
        </div>
      }

    </MinCartStyle>
    <ViewCartPopup ref={popRef}
      content={{
        open: open,
        setOpen: setOpen,
        section: "miniCart",
        ...props
      }} />

  </>);
}

