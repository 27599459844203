import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import Router from "next/router";
import api from "../api/Apis";
import "../../styles/globals.css";
import "../../styles/Home.module.css";
import "animate.css/animate.min.css";
import "../../styles/Responsive.module.css";
import Minicart from "./[page]/cart/Minicart";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ParallaxProvider } from "react-scroll-parallax";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookie from "js-cookie";
import Head from "next/head";
// import { toast } from 'react-toastify';
import ReactGA from "react-ga";
import dynamic from "next/dynamic";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { BYthreeGetONeFree } from "@Components/Common/commonMethods";
import * as Sentry from "@sentry/react";

const NavBar = dynamic(() => import("../Components/NavBar"), { ssr: false });
const Footer = dynamic(() => import("../Components/Footer"), { ssr: false });

function MyApp({ Component, pageProps }) {
  const wrapperRef = useRef(null);
  const [isLogin, setisLogin] = useState();
  const [isLoginToken, setisLoginToken] = useState('');
  const [usernotifications, setUsernotification] = useState();
  const [isLogout, setisLogout] = useState();
  const [oldpath, setoldpath] = useState();
  const [counts, setcounts] = useState();
  const [updatecartdata, setupdatecartdata] = useState(false);
  var randomstring = require("randomstring");
  const router = useRouter();

  // const showHeader = router.pathname === "/[page]/signup" ? false : true;
  const [leadIdData, setLeadIddata] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const [showminicart, setshowminicart] = useState(false);
  const [showloader, setshowloader] = useState(false);
  const [innerLoader, setInnerLoader] = useState(false);
  const [showprotectedpage, setShowProtectedPage] = useState(false);
  // const [openRefer, setOpenRefer] = useState(false);

  const [customerCheck, setCustomerCheck] = useState(1);
  const [profileData, setProfileData] = useState();
  const [subDomain, setSubDomain] = useState("");
  const [validReferralCheck, setValidReferralCheck] = useState(false);

  const [analyticsCode, setAnalyticsCode] = useState({
    facebook: "837382719344418227664",
    google: "G-XZ37FF4HMP",
  });
  const [minCartAutoshipCheck, setMiniCartAutoshipCheck] = useState("False");

  const [smartShipCheck, setSmartShipCheck] = useState(false);
  const [cartdata, setCartData] = useState();
  const [promoterPackAvailability, setPromoterPackAvailibilty] =
    useState(false);
  const [loginError, setLoginError] = useState("");
  const [addNotify, setAddNotify] = useState("");
  const [correctRefferal, setCorrectRefferal] = React.useState({
    status: false,
    data: {},
  });
  const [getDetails, setDetail] = useState();
  const [countTotal, setCountTotal] = useState();
  const [dataReferer, setDataRefer] = useState();


  const [amountCalculations, setAmountCalculations] = useState();
  const [coupon, setcoupon] = useState();

  const code = router?.query?.token;

  useEffect(() => {
    getUrlParamsProduct()
  }, [])




  useEffect(() => {
    const currentURL = window.location.href;
    const urlObject = new URL(currentURL);

    const { hostname, protocol, pathname, search, hash, host } = urlObject;
    if (host.startsWith('www.')) {
      const newHostname = host.replace('www.', '');
      const newURL = `${protocol}//${newHostname}${pathname}${search}${hash}`;
      window.location.replace(newURL);
    }
  }, []);

  // check current path and valiadte

  // if (router.pathname === '/[page]/signup') {
  //   if (isLogin === true) {
  //     router.back()
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // const handleBeforeUnload = (e) => {
  //   e.preventDefault();
  //   const message =
  //     "Are you sure you want to leave? All provided data will be lost.";
  //   e.returnValue = message;
  //   return message;
  // };



  const getUrlParamsProduct = () => {
    const hasHitApi = localStorage.getItem('hasHitApi');
    const urlParams = new URL(router.asPath, 'https://shopkaire.com/');
    const productParams = urlParams?.searchParams?.get('product')?.split(',');
    const token = urlParams?.searchParams?.get('token');
    const formDataObjects = {};
    if (productParams?.length > 0 && !hasHitApi) {
      localStorage.setItem('hasHitApi', true);
      for (const [index, productParam] of productParams.entries()) {
        const [productId, quantity, isAutoship] = productParam?.split(':');
        const formData = {
          product_id: parseInt(productId),
          token: token,
          quantity: parseInt(quantity),
          is_autoship: isAutoship === 'oneTime' ? false : true
        };
        formDataObjects[`formData${index + 1}`] = formData;
      }
      Promise.all(Object.values(formDataObjects).map((formData) => {
        return api.addToCart(formData);
      })).then((responses) => {
        responses.forEach((res) => {
          if (res?.data?.code === 1) {
            setAddNotify('Added To Cart');
            setTimeout(() => {
              setAddNotify('');
            }, 2000);
            setshowminicart(true);
          }
        });
      }).finally(() => {
        router.push('/us/checkout/addresslist/');
        setshowminicart(false);
      }).catch((error) => {
        console.error('API Error:', error);
      });
    }
  };

  // on click logout
  const handleLogout = async () => {
    const formdata = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : "";
    await api.logoutApi(formdata).then((res) => {
      if (res?.data?.code === 1) {
        localStorage.removeItem("Token");
        localStorage.removeItem("address_id");
        localStorage.removeItem("Login");
        localStorage.removeItem("packProduct");
        localStorage.removeItem("PromoterPack");
        localStorage.removeItem("profileDetail");
        localStorage.removeItem("referral_code");
        localStorage.removeItem("shippingAddress");
        localStorage.removeItem("correctRefferal");
        localStorage.removeItem("profileData");
        localStorage.removeItem("hasHitApi");
        Cookie.remove("sessionkey");
        // toast.success(res?.data?.message);
        setMiniCartAutoshipCheck("False");
        setSmartShipCheck(false);
        WishlistAllCart(null);
        setProfileData();
        setCustomerCheck(1);
        setUsernotification();
        setisLogin(false);
        setValidReferralCheck(false);
        router.push(`/`);
        setcounts();
        setCartData([]);
      }
      if (res?.data?.code === 0) {
        localStorage.removeItem("Token");
      }
    });
  };
  // qty counts in side cart
  // useEffect(() => {
  //   let QTYCOUNTS = cartdata?.products?.reduce(
  //     (prevValue, currentValue) => +prevValue + (+currentValue?.quantity), 0
  //   );
  //   setcounts(QTYCOUNTS)
  // }, [counts, cartdata])

  //cart data
  async function WishlistAllCart(token) {
    // setshowloader(true)
    if (token) {
      await api.getAllCartProduct(token).then((res) => {
        if (res?.status === 200 && res?.data?.code === 1) {
          const dataSss = localStorage.getItem("packProduct")
            ? JSON.parse(localStorage.getItem("packProduct"))
            : null;
          let QTYCOUNTS = res?.data?.products?.reduce(
            (prevValue, currentValue) =>
              +prevValue + (+currentValue?.quantity + currentValue?.extra_qty),
            0
          );
          let couuntValue = dataSss !== null ? +QTYCOUNTS + 1 : +QTYCOUNTS;
          setcounts(couuntValue);
          // setcounts(res?.data?.products?.length)
          let data = _.map(res?.data.products, ({ product }) => +product?.id);
          setAddNotify(data);
          // setshowloader(false)
        }
      });
    }
  }
  const LoginSubmit = async (data, setError) => {
    await api.loginUser(data).then((res) => {
      if (res?.data?.code == 1) {
        Sentry.setUser({ id: res?.data?.user_data?.userdetails?.[0]?.public_user_id, email: res?.data?.user_data?.email });
        if (cartdata?.products?.length > 0) {
          router.push("/us/checkout/addresslist");
        } else {
          router.push("/us/user/dashboard/");
        }
        // setDetail(res?.data?.user_data?.userdetails?.[0]?.paid_as_rank)
        // localStorage.setItem("profileData", JSON.stringify(res?.data))
        setInnerLoader(false);
        subdomainUserCheckFunction(res.data.token);
        setisLogin(true);
        localStorage.setItem("Login", true);
        localStorage.setItem("Token", res.data.token);
        getMiniCartProduct("onLoadData", res?.data?.token);
        WishlistAllCart(res?.data?.token);
        ProfileFunction(res?.data?.token);
        setCustomerCheck(
          res?.data?.user_data?.userdetails?.[0]?.qualified_as_rank
        );
        // toast('Successfully Login', { duration: 5, type: "success", });
      }

      if (res?.data?.code === 0 && res?.data?.message) {
        setError("email", {
          type: res?.data?.message,
          message: res?.data?.message,
        });
        setInnerLoader(false);
      }
    });
  };
  //mincart api
  async function getMiniCartProduct(section = "", logintoken) {
    // setshowloader(true)
    await api.getAllCartProduct(logintoken).then((res) => {
      if (res?.status === 200) {
        const dataSss = localStorage.getItem("packProduct")
          ? JSON.parse(localStorage.getItem("packProduct"))
          : null;
        if (router?.asPath === "us/checkout/addresslist") {
          if (
            res?.data?.products?.length === undefined &&
            dataSss?.customPack?.products?.length === undefined
          ) {
            router.push("/");
          }
        }

        // setshowloader(false)
        if (res?.data?.code === 1) {
          const CART_DATA_ARRAY = BYthreeGetONeFree(res?.data);
          setCartData(CART_DATA_ARRAY);
          setCartData(res?.data);
          const filterData = _.filter(res?.data?.products, {
            is_autoship: "True",
          });
          const promoterCheck = _.find(res?.data?.products, { promoter: true });
          let smartShipOnCart = filterData?.length > 0 ? true : false;
          setSmartShipCheck(smartShipOnCart);
          let data = _.map(res?.data.products, ({ product }) => +product?.id);
          setAddNotify(data);

          if (promoterCheck) {
            setPromoterPackAvailibilty(true);
          } else {
            setPromoterPackAvailibilty(false);
          }
          let QTYCOUNTS = res?.data?.products?.reduce(
            (prevValue, currentValue) =>
              +prevValue + (+currentValue?.quantity + currentValue?.extra_qty),
            0
          );
          let couuntValue = dataSss !== null ? +QTYCOUNTS + 1 : +QTYCOUNTS;
          setcounts(couuntValue);
          // if (section === "onLoadData") {
          // let couuntValue = (dataSss !== null) ? +counts + 1 : +counts
          // setcounts(couuntValue)
          // }
        } else {
          setSmartShipCheck(false);
          // setcounts([]);
          if (dataSss === null) {
            setCartData(res?.data);
            setcounts(0);
          } else {
            setcounts(1);
            setCartData({});
          }
        }
      }
    });
  }

  //referral code check function

  async function subdomainUserCheckFunction(token) {
    const subDomainDirect = window.location.hostname.split(".")[0];
    // setshowloader(true)

    if (
      subDomainDirect !== "" &&
      window.location.hostname !== "shopkaire.com"
    ) {
      try {
        // setshowloader(true)
        const res = await api.subDomainCheckFunction({
          referral_code: subDomainDirect,
        });
        if (res?.status === 200) {
          setValidReferralCheck(res?.data?.status);
          if (res?.data?.status !== true) {
            // localStorage.removeItem('Token');
            // localStorage.removeItem('address_id');
            // localStorage.removeItem('Login');
            // window.location.assign(process.env.domainName);

            if (cartdata?.products?.length > 0) {
              router.push("/us/checkout/addresslist");
            }
            // else {
            //   router.push("/us/user/dashboard/");
            // }

            setCorrectRefferal({ status: res?.data?.status, data: {} });
          } else {
            const { user_details } = res?.data?.data;
            setCorrectRefferal({
              status: res?.data?.status,
              data: {
                name: user_details?.name,
                email: user_details?.email,
                phone: user_details?.phone,
              },
            });
            localStorage.setItem(
              "referral_code",
              JSON.stringify(subDomainDirect)
            );
            localStorage.setItem(
              "correctRefferal",
              JSON.stringify({
                status: res?.data?.status,
                data: {
                  name: user_details?.name,
                  email: user_details?.email,
                  phone: user_details?.phone,
                },
              })
            );
            setAnalyticsCode({
              facebook: res?.data?.facebook_analytic_key
                ? res?.data?.facebook_analytic_key
                : "837382719344418227664",
              google: res?.data?.google_analytic_key
                ? res?.data?.google_analytic_key
                : "G- XZ37FF4HMP",
            });

            // if (cartdata?.products?.length > 0) {
            //   router.push('/us/checkout/addresslist')
            // } else {
            //   router.push('/us/user/dashboard/');
            // }
          }
        }
      } catch (error) {
        return error.response;
      }
    }
  }

  //profiledata data
  async function ProfileFunction(token, data_update = false) {
    if (token) {
      const data = localStorage.getItem("profileData");
      setInnerLoader(true);
      if (data && !data_update) {
        const values = JSON.parse(data);
        setProfileData(values);
        // setProfileData(+values?.user_data?.userdetails?.[0]?.paid_as_rank)
        setMiniCartAutoshipCheck(
          values?.user_data?.userdetails[0]?.is_autoship_user
        );
        setCustomerCheck(
          +values?.user_data?.userdetails?.[0]?.qualified_as_rank
        );
        setInnerLoader(false);
      } else {
        setInnerLoader(true);
        await api.getMyProfileDetails(token).then((res) => {
          if (res?.data?.code === 1) {
            setInnerLoader(false);
            localStorage.setItem("profileData", JSON.stringify(res?.data));
            setProfileData(res?.data);
            setMiniCartAutoshipCheck(
              res?.data?.user_data?.userdetails[0]?.is_autoship_user
            );
            setCustomerCheck(
              +res?.data?.user_data?.userdetails?.[0]?.qualified_as_rank
            );
            setInnerLoader(false);
          } else {
            setInnerLoader(false);
          }
        });
      }
    }
  }
  useEffect(() => {
    const subDomain = (window.location.hostname).split('.');
    if (window.location.hostname !== "shopkaire.com" && (subDomain?.length > 0)) {
      setSubDomain(subDomain[0])
      // setSubDomain()

      if (subDomain[0] !== "localhost" && subDomain[0] !== "192") {
        subdomainUserCheckFunction();
      }
    }
    const formdata = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';
    getMiniCartProduct("onLoadData", formdata);

  }, []);

  async function clickapi() {
    const headers = { "Content-Type": "application/json" };
    if (leadIdData) {
      await axios
        .post(process.env.clickedapi, { lead_id: leadIdData }, { headers })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    if (validReferralCheck === true) {
      ReactGA.initialize(`${analyticsCode?.google}`, { debug: true });
    }
    const lead_id = router?.query?.lead_id ? router?.query?.lead_id : null;
    if (lead_id) {
      localStorage.setItem("leadid", lead_id);
      setLeadIddata(lead_id);
      clickapi();
    }
  }, [validReferralCheck, leadIdData, router?.query?.lead_id]);

  useEffect(() => {
    setisLogin(
      localStorage.getItem("Login")
        ? JSON.parse(localStorage.getItem("Login"))
        : false
    );
    const formdata = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : "";
    setisLoginToken(formdata);
    if (formdata === "" || formdata === undefined) {
      if (Cookie.get("sessionkey")?.length !== 16) {
        Cookie.set("sessionkey", randomstring.generate(16));
      }
    }

    if (updatecartdata) {
      WishlistAllCart(formdata);
      setupdatecartdata(false);
    }
    ProfileFunction(formdata);
    formdata !== "" || (isLogin === true && validateauth(formdata));
    code !== undefined && setshowloader(true);
    api.LoginCheckByurl(code).then((res) => {
      if (res?.data?.code === 1) {
        setisLogin(true);
        localStorage.removeItem("profileData");
        localStorage.setItem("Login", true);
        localStorage.setItem("Token", code);
        getMiniCartProduct("onLoadData", res?.data?.token);
        ProfileFunction(code);
        router.push(`/us/user/dashboard/`);
        setTimeout(() => {
          setshowloader(false);
        }, 5000);
      } else {
        setshowloader(false);
      }
    });
  }, [isLogin, updatecartdata, code]);

  Router.onRouteChangeStart = () => {
    // setshowloader(true);
    // setInnerLoader(true)
  };

  Router.onRouteChangeComplete = () => {
    // setshowloader(false)
    setInnerLoader(false);
  };

  Router.onRouteChangeError = () => {
    // setshowloader(true)
    // setInnerLoader(true)
  };

  const validateauth = async (token) => {
    await api.LoginCheck(token || isLoginToken).then((res) => {
      if (res?.data?.code === 0) {
        localStorage.removeItem("Token");
        localStorage.removeItem("address_id");
        localStorage.removeItem("Login");
        setisLogin(false);
        setisLogout(true);
        setShowProtectedPage(false);
        // toast.error(res?.data?.message);
        router.push("/");
      }
      if (res?.data?.code === 1) {
        setShowProtectedPage(true);
      }
    });
  };

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <base href="/" />
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${analyticsCode?.google}`}
        ></script>
        <script></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(){ 
            var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
            s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', 
            footerHtml : 'provided by Kaire', hideMobile : false,
            hideTrigger : false, disableBgProcess : false, language : 'en', 
            position : 'left', leadColor : '#06356a', triggerColor : '#06356a', triggerRadius : '50%', 
            triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people',
            triggerSize : 'small', triggerOffsetX : 10, triggerOffsetY : 10,
            mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom',
            triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : '50%' } }); }; h.appendChild(s); })();`,
          }}
        />
        {/* <!-- Facebook Pixel Code --> */}
        {!validReferralCheck && analyticsCode?.facebook && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `
          !function(f,b,e,v,n,t,s)
            {
            if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                      n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                    };
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${analyticsCode?.facebook}');
            fbq('track', 'PageView');`,
              }}
            />
            {/* // */}
            <noscript>
              <img
                height="1"
                width="1"
                src="https://www.facebook.com/tr?id=837382719344418227664&ev=PageView&noscript=1"
              />
            </noscript>
          </>
        )}
      </Head>

      <NavBar
        setoldpath={setoldpath}
        handleLogout={handleLogout}
        innerLoader={innerLoader}
        cartdata={cartdata}
        setInnerLoader={setInnerLoader}
        isLogin={isLogin}
        isLoginToken={isLoginToken}
        notificationSection={{ usernotifications, setUsernotification }}
        setisLogin={setisLogin}
        counts={counts}
        subDomain={subDomain}
        correctRefferal={correctRefferal}
        setcounts={setcounts}
        updatecartdata={setupdatecartdata}
        customerCheck={customerCheck}
      />

      <ParallaxProvider>
        <div className="main_div">
          <Component
            ProfileFunction={ProfileFunction}
            dataReferer={dataReferer}
            setDataRefer={setDataRefer}
            minCartAutoshipCheck={minCartAutoshipCheck}
            subdomainUserCheckFunction={subdomainUserCheckFunction}
            subDomain={subDomain}
            getMiniCartProduct={getMiniCartProduct}
            innerLoader={innerLoader}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            setInnerLoader={setInnerLoader}
            setSubDomain={setSubDomain}
            validReferralCheck={validReferralCheck}
            setValidReferralCheck={setValidReferralCheck}
            profileData={profileData}
            showloader={showloader}
            getDetails={getDetails}
            LoginSubmit={LoginSubmit}
            loginError={loginError}
            customerCheck={customerCheck}
            setCustomerCheck={setCustomerCheck}
            validateauth={validateauth}
            setshowloader={setshowloader}
            setcounts={setcounts}
            counts={counts}
            setshowminicart={setshowminicart}
            setoldpath={setoldpath}
            oldpath={oldpath}
            setisLogin={setisLogin}
            cartdata={cartdata}
            setCartData={setCartData}
            addNotify={addNotify}
            setAddNotify={setAddNotify}
            isLogin={isLogin}
            signUpRefferal={{ correctRefferal, setCorrectRefferal }}
            smartShipCheck={smartShipCheck}
            setSmartShipCheck={setSmartShipCheck}
            {...pageProps}
            setupdatecartdata={setupdatecartdata}
            showprotectedpage={showprotectedpage}
            promoterPackAvailability={promoterPackAvailability}
            amountCalculations={amountCalculations}
            setAmountCalculations={setAmountCalculations}
            coupon={coupon}
            setcoupon={setcoupon}
          />
        </div>
      </ParallaxProvider>

      <Minicart
        cartdata={cartdata}
        setCartData={setCartData}
        getMiniCartProduct={getMiniCartProduct}
        setcounts={setcounts}
        counts={counts}
        minCartAutoshipCheck={minCartAutoshipCheck}
        showminicart={showminicart}
        setshowloader={setshowloader}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        setInnerLoader={setInnerLoader}
        setshowminicart={setshowminicart}
        smartShipCheck={smartShipCheck}
        isLogin={isLogin}
        amountCalculations={amountCalculations}
        setAmountCalculations={setAmountCalculations}
        coupon={coupon}
        setcoupon={setcoupon}
      />

      <Footer
        showloader={showloader}
        setshowloader={setshowloader}
        innerLoader={innerLoader}
        setActiveStep={setActiveStep}
        subDomain={subDomain}
        isLoginToken={isLoginToken}
        setInnerLoader={setInnerLoader}
        handleLogout={handleLogout}
        isLogin={isLogin}
        notificationSection={{ usernotifications, setUsernotification }}
        counts={counts}
        customerCheck={customerCheck}

      />

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default MyApp;
