import styled from "styled-components";

export const PackStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  @media (max-width: 767px) {
    row-gap: 20px;
  }
  && .promoter_pack_container {
    width: 100%;
    padding: 10px 10px 30px;
    box-shadow: 0px 0px 10px 2px #f3efef;
    background: #fff;
    max-width: calc(100% / 4 - 20px);
    flex: 100%;
    @media (max-width: 767px) {
      max-width: 100%;
    }
    > button {
      min-height: 460px;
      max-height: 460px;
      width: 100%;
      background: transparent !important;
      color: #06356a !important;
      border: unset !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 10px;
      span {
        font-weight: 600;
      }
    }
    .image_section {
      text-align: center;
      img {
        width: 100%;
        height: 260px;
        object-fit: contain;
      }
      h3 {
        font-weight: 600;
        font-family: "Lato";
        color: #00356a;
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
    }
    .content_section {
      text-align: center;
      button {
        font-size: 12px;
        border-radius: 50px;
        height: 40px;
        width: 130px;
        padding: 0;
        /* color:#fff; */
        &.add_cart {
          margin-top: 7px;
        }
      }
      .cost_class {
        display: flex;
        justify-content: center;
        b {
          padding-right: 6px;
          font-weight: 500;
        }
        &.retail_section {
          font-size: 18px;
          font-weight: 600;
          color: rgb(0, 0, 0);
        }
        &.smartShip_section {
          font-size: 15px;
          font-weight: 600;
          margin: 10px auto;
        }
      }
    }
    #qty {
      display: flex;
      padding: 10px 0px;
      width: 100%;
      justify-content: center;
      padding: 0 !important;
      border: 1px solid var(--blue);
      max-width: 120px;
      margin: 10px auto;
      input {
        text-align: center;
        border-radius: 0;
        border: none;
        background: #fff;
        max-width: 40px;
        margin-bottom: 0;
        color: var(--blue);
        width: 40px;
        border-left: 1px solid var(--blue);
        border-right: 1px solid var(--blue);
      }
      button {
        width: inherit;
        border-radius: unset;
        border: unset;
        background: none;
        justify-content: center;
        color: #000;
        font-size: 16px;
        font-weight: 400;
        padding: 0 7px !important;
      }
    }
  }
`;

const CustomPack = styled(PackStyle)`
  && .promoter_pack_container {
    padding: unset;
    margin: unset;
    .image_section {
      img {
        width: 70%;
      }
    }
  }
  .add_to_custom_pack {
    width: 100%;
    text-align: center;
    color: #fff;
    button {
      padding: 12px 15px;
      border-radius: 25px;
      &.disable_class {
        color: #fff;
        border: 1px solid #dddd;
        background: #dddd;
      }
      &.enable_class {
        &:hover {
          color: #06356a;
          border: 1px solid #06356a;
          background: #fff;
        }
      }
    }
  }
`;

const UpperTextCss = styled.div`
  > p {
    text-align: center;
    font-size: 18px;
    color: green;
  }
  && .bonus_main_container {
    width: 100%;
    margin-bottom: 20px;
    .common_value {
      display: flex;
      gap: 10px;
      justify-content: end;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

export default PackStyle;
export { UpperTextCss, CustomPack };
