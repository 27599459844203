export const BYthreeGetONeFree = (data) => {
    const CART_DATA_ARRAY = [];
    _.filter(data?.products, (row) => {
        const { extra_qty, ...values } = row;
        CART_DATA_ARRAY.push({ ...values, extra_qty: 0 });
        if (extra_qty) {
            const { extra_qty, ...other } = row;
            CART_DATA_ARRAY.push({
                ...other, product: {
                    ...other?.product,
                    cost_price: "0.00",
                    autoship_cost_price: "0.00"
                },
                extra_qty: 0,
                extraProduct: true,
                quantity: extra_qty
            })
        }
    })
    data['products'] = CART_DATA_ARRAY;

    return data;
}