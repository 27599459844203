import NumberFormat from 'react-number-format';
import { AiFillDelete } from 'react-icons/ai';
import { IconButton } from '@material-ui/core';
import _ from 'lodash';

const Variant = ({ smartShipCheck, Cart, deleteproduct, minCartAutoshipCheck, index, register, Add, Sub, updateqty, setcosttype, costtype, swithtoautoship }) => {
  //check product is selected to autoship or normal

  return (<>
    <div className="mini-cart" key={1}>
      <div className="min-cat-uppper">
        <div className="cart-image">
          {Cart?.variant?.product_variant_images[0]?.image
            ?
            <img src={`${process.env.API_URL}${Cart?.variant.product_variant_images[0]?.image}`} />
            :
            <img src="/images/no-image.jpg" />
          }
        </div>

        <div className="mini-cart-des">
          <div className="cart-product-details">
            {Cart?.product?.name}({Cart?.variant?.name})
          </div>
          <div className="cart-product-details">
            <div className="main-qty-sec">
              <div className="box">
                {Cart?.qty_display &&
                  <div id="qty">
                    <button type="button" name={`${Cart?.product?.id},${Cart?.variant?.id}`} data-value={Cart?.variant?.quantity} id={Cart?.variant?.id} className="sub" value={Cart?.quantity} onClick={(e) => { Sub(e) }}>-</button>
                    <input
                      name={`${Cart?.product?.id},${Cart?.variant?.id}`}
                      type="text"
                      readOnly
                      ref={register &&
                        register({
                          valueAsNumber: true,
                        })
                      }
                      value={+(updateqty?.id) === +(Cart?.variant?.id) ? +(updateqty.value) : Cart?.quantity}

                      min={1}
                      max={Cart?.variant?.quantity}
                    />
                    <button type="button" name={`${Cart?.product?.id},${Cart?.variant?.id}`} data-value={Cart?.variant?.quantity} id={Cart?.variant?.id} className="add" value={Cart?.quantity} onClick={(e) => { Add(e) }}>+</button>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="cart-product-details">
            <NumberFormat value={parseFloat(Cart?.variant?.cost_price * Cart?.quantity).toFixed(2)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              renderText={value => <div> {
                // (Cart?.is_autoship !== 'True' && minCartAutoshipCheck !== "True")
                (minCartAutoshipCheck !== "True" && smartShipCheck !== true)
                  ?
                  value
                  :
                  // `$${parseFloat(Cart?.variant?.[`${minCartAutoshipCheck === "True" ? "autoship_cost_price" : "cost_price"}`] * Cart?.quantity).toFixed(2)}` + ' / $' + parseFloat(Cart?.variant?.autoship_cost_price * Cart?.quantity).toFixed(2) + ' SmartShip'}</div>} />
                  `$${parseFloat(Cart?.variant?.[`${(minCartAutoshipCheck === "True" || smartShipCheck) ? "autoship_cost_price" : "cost_price"}`] * Cart?.quantity).toFixed(2)}` + ' / $' + parseFloat(Cart?.variant?.autoship_cost_price * Cart?.quantity).toFixed(2) + ' SmartShip'}</div>} />
          </div>

          <div className="box">
            <div className="select">
              <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                data-product-id={Cart?.product?.id}
                data-product-qty={Cart?.quantity}
                data-variant-id={+Cart?.variant?.id}
                value={Cart?.is_autoship === "True" ? "AutoShip" : "Normal"}
                onChange={(e) => {
                  const found = costtype.data.some(el => el.id === Cart?.variant?.id);
                  if (!found) {
                    setcosttype({ ...costtype, data: [...costtype.data, { "id": Cart?.variant?.id, "value": e.target.value }] })
                  }
                  else {
                    const objIndex = costtype.data.findIndex((obj => obj.id == Cart?.variant?.id));
                    costtype.data.splice(objIndex, 1);

                    setcosttype({ ...costtype, data: [...costtype.data, { "id": Cart?.variant?.id, "value": e.target.value }] })

                  }
                  swithtoautoship(e)

                }}

              >
                <option value="Normal" >One-time Only</option>
                {/* <option value="AutoShip" selected={Cart?.is_autoship === 'True' ? true : false} >SmartShip</option> */}
                <option value="AutoShip"  >SmartShip</option>
              </select>
            </div>
          </div>

        </div>
      </div>
      <div className="cart-product-qty-del">
        {/* <button className="dlt" id={Cart?.product?.id} data-value={Cart?.variant?.id} onClick={(e) => { deleteproduct(e) }}><AiFillDelete /></button> */}

        <button className="dlt" id={Cart?.product?.id} data-value={Cart?.variant?.id} onClick={(e) => { deleteproduct(e) }} >X</button>
      </div>
    </div>
  </>)
}
export default Variant;