import React from 'react';
import _ from 'lodash';
import usePermoterhook from './PromoterPack';
import DialogComponent from '@Components/Common/DialogComponent';
import InnerHtml from './commonPackJsx';
import CustomPackComp from './CustomPackComp';
import PackStyle from './PromoterCompStyle';

function PackProduct(props) {
    // const router = useRouter();

    const [
        packProduct, addToCart,
        allProductData,
        open, setOpen,
        customSelectedData, setCustomeData,
        eligiableMsg, setEligiableMsg,
        minBV, setMinBv,
        totalAmount, setTotalAmount,
        quantityCheck, setQuantityCheck,
        totalWeight, setTotalWeight
    ] = usePermoterhook(props)

    const common = {
        setOpen,
        customSelectedData, setCustomeData,
        eligiableMsg, setEligiableMsg,
        minBV, setMinBv, totalAmount, setTotalAmount,
        quantityCheck, setQuantityCheck,
        totalWeight, setTotalWeight
    }
    return (
        <>
            <PackStyle>
                {/* {_.map(_.reject(packProduct, { name: props?.partOf }), (row, index) => ( */}
                <InnerHtml
                    contents={{
                        data: packProduct || [],
                        partOf: props?.partOf,
                        content: "normal",
                        section: props?.section || "packsec",
                        ...common,
                        addToCart
                    }}
                />
            </PackStyle>
            <DialogComponent opend={open} handleClose={setOpen} title="Custom Pack" classFor="customPackPopup">
                <CustomPackComp {...props} />
            </DialogComponent>
        </>
    )
}

export default PackProduct;