import React, { useEffect, useState } from 'react'
import { SecondTableCss } from './ViewCartStyle'
import { FaTrash } from "react-icons/fa";
import { AiFillEdit } from 'react-icons/ai';
import DialogComponent from "@Components/Common/DialogComponent";
import CustomPackComp from "@PagesComponent/PromoterPack/CustomPackComp";
import NumberFormatComp from "@Components/Common/NumberFormatComp";

function CustomPackTable({ children, setCheckCustomPack, setshowloader, ...props }) {
    const [customPackData, setCustomPackData] = useState({
        products: [],
        totalAmount: 0
    });
    const [openCustomPack, setCustomePackClose] = useState(false);

    //delete customPack section
    const _removeCustomPack = () => {
        localStorage.removeItem('packProduct');
        setCustomPackData({
            products: [],
            totalAmount: 0
        });
        setCheckCustomPack({ products: [], totalAmount: 0 })
    }

    useEffect(() => {
        const data = localStorage.getItem('packProduct');
        if (data) {
            let values = JSON.parse(data);
            const valuesData = {
                products: values?.customPack?.products,
                totalAmount: values?.customPack?.totalAmount
            }
            setCustomPackData(valuesData);
        }
    }, []);

    return (
        <>
            {customPackData?.products?.length > 0 &&
                <SecondTableCss>
                    <div className="heading_of_pack">
                        <div className="title_of_pack">Your Starter Promoter Pack</div>
                        <div className="amount_and_edit">
                            <div className="button_for_edit">
                                <div><AiFillEdit fontSize={22} onClick={() => {
                                    if (props?.section === "review_from_stepper") {
                                        props?.goTo();
                                    } else {
                                        setCustomePackClose(true);
                                    }
                                }} /></div>
                                <div><FaTrash fontSize={20} onClick={(e) => _removeCustomPack(e)} color="red" /></div>
                            </div>
                            <div className="amount">
                                <NumberFormatComp value={customPackData?.totalAmount} />
                            </div>
                        </div>
                    </div>

                    <div className="table_container">
                        <table className="Cart_product whistlist-table">
                            <thead>
                                <tr>
                                    <th>product name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.map(customPackData?.products, (row, index) => (
                                    <tr key={index + 1}>
                                        <td>{row?.product_name}</td>
                                        <td>{row?.qty}</td>
                                        <td>${parseFloat(row?.autoship_cost_price).toFixed(2)}</td>
                                    </tr>
                                ))}
                                {children}
                            </tbody>
                        </table>
                    </div>
                </SecondTableCss>
            }
            <DialogComponent opend={openCustomPack} handleClose={setCustomePackClose} title="Custom Pack" classFor="customPackPopup">
                <CustomPackComp section="viewCart" setshowloader={setshowloader} />
            </DialogComponent>
        </>
    )
}

export default CustomPackTable
